import React, { useState, useImperativeHandle, forwardRef, useRef } from "react";
import "./style.css";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const statesMap = {
	Norte: ["Acre", "Amapá", "Amazonas", "Pará", "Rondônia", "Roraima", "Tocantins"],
	Nordeste: [
		"Alagoas",
		"Bahia",
		"Ceará",
		"Maranhão",
		"Paraíba",
		"Pernambuco",
		"Piauí",
		"Rio Grande do Norte",
		"Sergipe",
	],
	Sul: ["Paraná", "Rio Grande do Sul", "Santa Catarina"],
	Sudeste: ["Espírito Santo", "Minas Gerais", "Rio de Janeiro", "São Paulo"],
	"Centro-Oeste": ["Distrito Federal", "Goiás", "Mato Grosso", "Mato Grosso do Sul"],
};

function Start({ next }, ref) {
	const [values, setValues] = useState({});
	const [validated, setValidated] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;

		if (form.checkValidity()) {
			next();
		}

		setValidated(true);
	};

	useImperativeHandle(ref, () => {
		return {
			values,
		};
	});

	return (
		<div className="container">
			<div className="top">
				<h3>Dados do Examinado</h3>
			</div>
			<div className="middle slide1">
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Row>
						<Col xs={4}>
							<Form.Group>
								<Form.Label>Data</Form.Label>
								<Form.Control
									required
									type="date"
									onChange={(e) => setValues({ ...values, date: e.target.value })}
								/>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={4}></Col>
						<Col xs={4}>
							<Form.Group>
								<Form.Label>Código do participante</Form.Label>
								<Form.Control
									required
									type="text"
									onChange={(e) => setValues({ ...values, code: e.target.value })}
								/>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<div className="block-label">Identificação</div>
						<Col xs={6}>
							<Form.Group>
								<Form.Label>Nome</Form.Label>
								<Form.Control
									required
									type="text"
									onChange={(e) => setValues({ ...values, name: e.target.value })}
								/>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={3}>
							<Form.Group>
								<Form.Label>Estado Civil</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, marital_status: e.target.value })}
								>
									<option value=""></option>
									<option value="Solteiro">Solteiro</option>
									<option value="Casado/Mora junto">Casado/Mora junto</option>
									<option value="Viúvo">Viúvo</option>
									<option value="Separado/Divorciado">Separado/Divorciado</option>
									<option value="Outros">Outros</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={3}>
							<Form.Group>
								<Form.Label>Sexo</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, gender: e.target.value })}
								>
									<option value=""></option>
									<option value="Masculino">Masculino</option>
									<option value="Feminino">Feminino</option>
									<option value="Outro">Outro</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<div className="block-label">Dados do nascimento</div>
						<Col xs={4}>
							<Form.Group>
								<Form.Label>Data de Nascimento</Form.Label>
								<Form.Control
									required
									type="date"
									max={moment().subtract(1, "day").format("YYYY-MM-DD")}
									onChange={(e) => setValues({ ...values, birthday: e.target.value })}
								/>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={8}></Col>
						<Col xs={3}>
							<Form.Group>
								<Form.Label>Região</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, region: e.target.value })}
								>
									<option value=""></option>
									<option value="Norte">Norte</option>
									<option value="Nordeste">Nordeste</option>
									<option value="Sul">Sul</option>
									<option value="Sudeste">Sudeste</option>
									<option value="Centro-Oeste">Centro-Oeste</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={4}>
							<Form.Group>
								<Form.Label>Estado</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, state: e.target.value })}
								>
									<option value=""></option>
									{statesMap[values.region] &&
										statesMap[values.region].map((state) => <option value={state}>{state}</option>)}
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={5}>
							<Form.Group>
								<Form.Label>Cidade onde nasceu</Form.Label>
								<Form.Control
									required
									type="text"
									onChange={(e) => setValues({ ...values, birthplace: e.target.value })}
								/>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<div className="block-label">Outros dados pessoais</div>
						<Col xs={5}>
							<Form.Group>
								<Form.Label>Escolaridade</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, schooling: e.target.value })}
								>
									<option value=""></option>
									<option value="6 ano ao 9 an">6 ano ao 9 ano</option>
									<option value="Ensino Médio Incompleto">Ensino Médio Incompleto</option>
									<option value="Ensino Médio Completo">Ensino Médio Completo</option>
									<option value="Superior Incompleto">Superior Incompleto</option>
									<option value="Superior Completo">Superior Completo</option>
									<option value="Pós-Graduação">Pós-Graduação</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={3}>
							<Form.Group>
								<Form.Label>Etnia</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, ethnicity: e.target.value })}
								>
									<option value=""></option>
									<option value="Branco">Branco</option>
									<option value="Negro">Negro</option>
									<option value="Pardo">Pardo</option>
									<option value="Asiático">Asiático</option>
									<option value="Índio">Índio</option>
									<option value="Outros">Outros</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={4}></Col>
						<Col xs={5}>
							<Form.Group>
								<Form.Label>Ocupação</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, ocupation: e.target.value })}
								>
									<option value=""></option>
									<option value="Ativo trabalhando">Ativo trabalhando</option>
									<option value="Inativo desempregado">Inativo desempregado</option>
									<option value="Aposentado">Aposentado</option>
									<option value="Auxílio doença">Auxílio doença</option>
									<option value="Outros">Outros</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={7}>
							<Form.Group>
								<Form.Label> Qual atividade você exerce atualmente?</Form.Label>
								<Form.Control
									required
									type="text"
									onChange={(e) => setValues({ ...values, activity: e.target.value })}
								/>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<div className="block-label">Renda</div>
						<Col xs={6}>
							<Form.Group>
								<Form.Label>Renda Mensal Familiar</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, familiy_income: e.target.value })}
									style={{ fontSize: 13 }}
								>
									<option value=""></option>
									<option>Menos de 1 salario mínimo (R$ 1.100,00)</option>
									<option>Entre 2 e 3 salários mínimos (Entre R$2.200,00 e R$3.300,00 )</option>
									<option>Entre 3 e 5 salários mínimos (Entre R$3.300,00 e R$5.500,00 )</option>
									<option>Mais que 5 salários mínimos (Mais que R$5.500,00 )</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={6}>
							<Form.Group>
								<Form.Label>Renda Mensal Pessoal</Form.Label>
								<Form.Control
									required
									as="select"
									onChange={(e) => setValues({ ...values, personal_income: e.target.value })}
									style={{ fontSize: 13 }}
								>
									<option value=""></option>
									<option>Menos de 1 salario mínimo (R$ 1.100,00)</option>
									<option>Entre 2 e 3 salários mínimos (Entre R$2.200,00 e R$3.300,00 )</option>
									<option>Entre 3 e 5 salários mínimos (Entre R$3.300,00 e R$5.500,00 )</option>
									<option>Mais que 5 salários mínimos (Mais que R$5.500,00 )</option>
								</Form.Control>
								<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<br />
						<Form.Label column sm="12">
							Quantas pessoas moram com você?
						</Form.Label>
						<Col sm="6">
							<Form.Control
								required
								type="number"
								style={{ width: 60 }}
								max={99}
								min={0}
								onChange={(e) => setValues({ ...values, people_lives: e.target.value })}
							/>
							<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<div className="block-label">Acompanhamento com psicólogo e/ou psiquiatra</div>
						<Form.Group>
							<Form.Label column sm="12">
								<b>Você já fez ou faz atualmente psicoterapia ou acompanhamento com psicólogo?</b>
							</Form.Label>
							<Col sm={2}>
								<Form.Check
									required
									type="radio"
									label="SIM"
									name="attendance1"
									id="attendance11"
									onChange={(e) => setValues({ ...values, attendance1: "sim" })}
									feedback="* obrigatório"
								/>
								<Form.Check
									required
									type="radio"
									label="NÃO"
									name="attendance1"
									id="attendance12"
									onChange={(e) => setValues({ ...values, attendance1: "não" })}
									feedback="* obrigatório"
								/>
							</Col>
							<Col xs={4} style={{ marginTop: 5 }}>
								<Form.Group>
									<Form.Label>Por quanto tempo?</Form.Label>
									<Form.Control
										disabled={!values.attendance1 || values.attendance1 === "não"}
										required={!values.attendance1 || values.attendance1 === "sim"}
										type="text"
										onChange={(e) => setValues({ ...values, attendance1_howlong: e.target.value })}
									/>
									<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Form.Label column sm="12">
								<b>Você já foi ou vai atualmente ao psiquiatra?</b>
							</Form.Label>
							<Col sm={2}>
								<Form.Check
									required
									type="radio"
									label="SIM"
									name="attendance2"
									id="attendance21"
									onChange={(e) => setValues({ ...values, attendance2: "sim" })}
								/>
								<Form.Check
									required
									type="radio"
									label="NÃO"
									name="attendance2"
									id="attendance22"
									onChange={(e) => setValues({ ...values, attendance2: "não" })}
								/>
							</Col>
							<Col xs={4} style={{ marginTop: 5 }}>
								<Form.Group>
									<Form.Label> Por quanto tempo?</Form.Label>
									<Form.Control
										disabled={!values.attendance2 || values.attendance2 === "não"}
										required={!values.attendance2 || values.attendance2 === "sim"}
										type="text"
										onChange={(e) => setValues({ ...values, attendance2_howlong: e.target.value })}
									/>
									<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<br />
							<Form.Label column sm="12">
								<b>Você já tomou ou toma atualmente algum medicamento psiquiátrico?</b>
							</Form.Label>
							<Col sm={2}>
								<Form.Check
									required
									type="radio"
									label="SIM"
									name="attendance3"
									id="attendance31"
									onChange={(e) => setValues({ ...values, attendance3: "sim" })}
								/>
								<Form.Check
									required
									type="radio"
									label="NÃO"
									name="attendance3"
									id="attendance32"
									onChange={(e) => setValues({ ...values, attendance3: "não" })}
								/>
							</Col>
							<Col xs={4} style={{ marginTop: 5 }}>
								<Form.Group>
									<Form.Label> Por quanto tempo?</Form.Label>
									<Form.Control
										disabled={!values.attendance3 || values.attendance3 === "não"}
										required={!values.attendance3 || values.attendance3 === "sim"}
										type="text"
										onChange={(e) => setValues({ ...values, attendance3_howlong: e.target.value })}
									/>
									<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<br />
							<Form.Label column sm="12">
								<b>Você tem algum diagnóstico psiquiátrico?</b>
							</Form.Label>
							<Col sm={2}>
								<Form.Check
									required
									type="radio"
									label="SIM"
									name="attendance4"
									id="attendance41"
									onChange={(e) => setValues({ ...values, attendance4: "sim" })}
								/>
								<Form.Check
									required
									type="radio"
									label="NÃO"
									name="attendance4"
									id="attendance42"
									onChange={(e) => setValues({ ...values, attendance4: "não" })}
								/>
							</Col>
							<Col xs={12} style={{ marginTop: 5 }}>
								<Form.Group>
									<Form.Label> Se sim, qual?</Form.Label>
									<Form.Control
										disabled={!values.attendance4 || values.attendance4 === "não"}
										required={!values.attendance4 || values.attendance4 === "sim"}
										type="text"
										onChange={(e) => setValues({ ...values, attendance4_which: e.target.value })}
									/>
									<Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Form.Group>
					</Row>
					<Row>
						<div className="block-label">Suicídio</div>
						<Form.Group>
							<Form.Label column sm="12">
								Você já pensou em cometer suicídio?
							</Form.Label>
							<Col sm={2}>
								<Form.Check
									required
									type="radio"
									label="SIM"
									name="suicide1"
									id="suicide11"
									onChange={(e) => setValues({ ...values, suicide1: "sim" })}
								/>
								<Form.Check
									required
									type="radio"
									label="NÃO"
									name="suicide1"
									id="suicide12"
									onChange={(e) => setValues({ ...values, suicide1: "não" })}
								/>
							</Col>
							<br />
							<Form.Label column sm="12">
								Você pensa em cometer suicídio?
							</Form.Label>
							<Col sm={2}>
								<Form.Check
									required
									type="radio"
									label="SIM"
									name="suicide2"
									id="suicide21"
									onChange={(e) => setValues({ ...values, suicide2: "sim" })}
								/>
								<Form.Check
									required
									type="radio"
									label="NÃO"
									name="suicide2"
									id="suicide22"
									onChange={(e) => setValues({ ...values, suicide2: "não" })}
								/>
							</Col>
							<br />
							<Form.Label column sm="12">
								Você já tentou cometer suicídio?
							</Form.Label>
							<Col sm={2}>
								<Form.Check
									required
									type="radio"
									label="SIM"
									name="suicide3"
									id="suicide31"
									onChange={(e) => setValues({ ...values, suicide3: "sim" })}
								/>
								<Form.Check
									required
									type="radio"
									label="NÃO"
									name="suicide3"
									id="suicide32"
									onChange={(e) => setValues({ ...values, suicide3: "não" })}
								/>
							</Col>
						</Form.Group>
					</Row>
					<br />
					<div style={{ textAlign: "center", marginBottom: 15 }}>
						<Button variant="primary" type="submit" size="lg">
							Avançar
						</Button>
					</div>
				</Form>
			</div>
			<div className="bottom"></div>
		</div>
	);
}

const StartRef = forwardRef(Start);

export default StartRef;

import React from "react";
import Button from "react-bootstrap/Button";

function Slide({ next, answers }) {
	return (
		<div className="container">
			<div className="middle message">
				Chegamos ao final do teste! <br /> <br />
				Obrigado!
			</div>
			<div className="bottom">
				<Button
					variant="primary"
					onClick={() => {
						next();
					}}
					size="lg"
				>
					Enviar respostas
				</Button>
			</div>
		</div>
	);
}

export default Slide;

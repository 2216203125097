import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./style.css";
import positions from "../../constants/positions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Avaliation({ next, answers, curSlide }, ref) {
	const [selected, setSelected] = useState(null);
	const [reason, setReason] = useState("");

	useImperativeHandle(ref, () => {
		return {
			selected,
			reason,
		};
	});

	function genPiramidHtml(pos) {
		if (!pos || !pos.current || !pos.current.final) return;
		pos = pos.current.final;
		let piramidHtml = [];

		positions.forEach((squares, i) => {
			piramidHtml.push(
				<div className="piramid-line" key={i}>
					{squares.map((square) => {
						return <div className={"piramid-square " + pos[square]} data-target={square}></div>;
					})}
				</div>
			);
		});
		return piramidHtml;
	}

	return (
		<div className="container">
			<div className="top">
				<h5 className="test-desc">Clique sobre a pirâmide que você achou menos bonita</h5>
			</div>
			<div className="middle avaliation">
				<div className="avaliation-piramids">
					<div
						className={`piramid-area ${selected === 1 ? "selected" : ""}`}
						onClick={() => setSelected(1)}
					>
						{genPiramidHtml(answers.test1)}
					</div>
					<div
						className={`piramid-area ${selected === 2 ? "selected" : ""}`}
						onClick={() => setSelected(2)}
					>
						{genPiramidHtml(answers.test2)}
					</div>
					<div
						className={`piramid-area ${selected === 3 ? "selected" : ""}`}
						onClick={() => setSelected(3)}
					>
						{genPiramidHtml(answers.test3)}
					</div>
				</div>
			</div>
			<div className="bottom">
				{selected && (
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Por que?</Form.Label>
						<Form.Control type="text" onChange={(e) => setReason(e.target.value)} />
					</Form.Group>
				)}
				{reason.length ? (
					<Button
						variant="primary"
						onClick={() => {
							next(1);
						}}
						size="lg"
					>
						Avançar
					</Button>
				) : (
					""
				)}
			</div>
		</div>
	);
}

const AvaliationRef = forwardRef(Avaliation);

export default AvaliationRef;

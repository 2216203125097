import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import IndexedDB from "./db";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Slide01 from "./components/Slide01";
import Slide02 from "./components/Slide02";
import Slide03 from "./components/Slide03";
import Slide04 from "./components/Slide04";
import Slide05 from "./components/Slide05";
import Slide06 from "./components/Slide06";
import Slide07 from "./components/Slide07";
import Slide08 from "./components/Slide08";
import Slide09 from "./components/Slide09";
import Slide10 from "./components/Slide10";
import Slide11 from "./components/Slide11";
import Slide12 from "./components/Slide12";
import Slide13 from "./components/Slide13";
import Slide14 from "./components/Slide14";

let db;

export default function App() {
  const [step, setStep] = useState(1);
  const [cpf, setCpf] = useState(null);
  const [hasCpf, setHasCpf] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [itemsOnDB, setItemsOnDB] = useState(true);

  useEffect(() => {
    const startDB = async () => {
      db = await IndexedDB();

      if (window.navigator.onLine) {
        const items = await db.getAll();

        if (items.length) {
          // envia para o servidor
          // await db.clear();
          setItemsOnDB(true);
        }
      }
    };

    const mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      setIsMobile(check);
    };

    startDB();
    mobileCheck();
  }, []);

  const carousel = useRef();

  const answers = {
    form: useRef(),
    test1: useRef(),
    test2: useRef(),
    test3: useRef(),
    question1: useRef(),
    question2: useRef(),
    question3: useRef(),
    question4: useRef(),
    question5: useRef(),
  };

  function next() {
    carousel.current.next();
    setTimeout(() => {
      setStep((prev) => {
        const newStep = prev + 1;

        if (newStep === 15) {
          finish();
        }

        return newStep;
      });
    }, 500);
  }

  function finish() {
    const data = {};

    for (const key in answers) {
      const obj = answers[key].current;

      if (obj.hasOwnProperty("values")) {
        data[key] = obj.values;
      } else if (obj.hasOwnProperty("selectedColor")) {
        data[key] = obj.selectedColor;
      } else {
        data[key] = obj;
      }
    }

    data["admin_cpf"] = cpf;

    if (window.navigator.onLine) {
      sendData(data);
    } else {
      db.add(data);
    }
  }

  function sendData(data) {
    //
    setItemsOnDB(false);
  }

  if (!hasCpf) {
    return (
      <div className="login-area">
        <Form.Group>
          <Form.Label>Informe seu CPF</Form.Label>
          <Form.Control required type="text" onChange={(e) => setCpf(e.target.value)} />
          <Form.Control.Feedback type="invalid">* obrigatório</Form.Control.Feedback>
        </Form.Group>
        <br />
        <div style={{ textAlign: "center", marginBottom: 15 }}>
          <Button variant="primary" type="button" size="lg" onClick={() => cpf && setHasCpf(true)}>
            Avançar
          </Button>
        </div>
      </div>
    );
  }

  if (itemsOnDB) {
    return (
      <div className="login-area">
        <Form.Group>
          <Form.Label>Você possui testes que ainda não foram enviados para o sistema</Form.Label>
        </Form.Group>
        <br />
        <div style={{ textAlign: "center", marginBottom: 15 }}>
          <Button variant="primary" type="button" size="lg" onClick={() => sendData(itemsOnDB)}>
            Enviar
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="pfister-area">
        <Carousel keyboard={false} controls={false} indicators={false} touch={false} wrap={false} ref={carousel} interval={1000 * 60 * 60 * 24}>
          <Carousel.Item>
            <Slide01 next={next} ref={answers.user} curSlide={step === 1} />
            <Modal show={isMobile} onHide={() => setIsMobile(false)} backdrop="static" keyboard={false}>
              <Modal.Body>ESTE PROGRAMA NÃO FUNCIONA ADEQUADAMENTE SE REALIZADO EM CELULAR.</Modal.Body>
            </Modal>
          </Carousel.Item>
          <Carousel.Item>
            <Slide02 next={next} curSlide={step === 2} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide03 next={next} curSlide={step === 3} />
          </Carousel.Item>{" "}
          <Carousel.Item>
            <Slide04 next={next} curSlide={step === 4} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide05 next={next} ref={answers.test1} curSlide={step === 5} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide06 next={next} curSlide={step === 6} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide07 next={next} ref={answers.test2} curSlide={step === 7} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide08 next={next} ref={answers.test3} curSlide={step === 8} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide09 next={next} ref={answers.question1} answers={answers} curSlide={step === 9} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide10 next={next} ref={answers.question2} answers={answers} curSlide={step === 10} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide11 next={next} ref={answers.question3} answers={answers} curSlide={step === 11} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide12 next={next} ref={answers.question4} curSlide={step === 12} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide13 next={next} ref={answers.question5} curSlide={step === 13} />
          </Carousel.Item>
          <Carousel.Item>
            <Slide14 next={next} curSlide={step === 14} />
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
}

import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Avaliation({ next, answers }, ref) {
	const [values, setValues] = useState({
		like_best: "",
		least_like: "",
	});

	useImperativeHandle(ref, () => {
		return {
			values,
		};
	});

	return (
		<div className="container">
			<div className="top">{/* <h3>Dados do Examinado</h3> */}</div>
			<div className="middle">
				<Form>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Qual sua cor preferida?</Form.Label>
						<Form.Control
							type="text"
							onChange={(e) => setValues({ values, like_best: e.target.value })}
						/>
					</Form.Group>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>E Qual a cor que você menos gosta?</Form.Label>
						<Form.Control
							type="text"
							onChange={(e) => setValues({ values, least_like: e.target.value })}
						/>
					</Form.Group>
				</Form>
			</div>
			<div className="bottom">
				<Button variant="primary" onClick={next} size="lg">
					Avançar
				</Button>
			</div>
		</div>
	);
}

const AvaliationRef = forwardRef(Avaliation);

export default AvaliationRef;

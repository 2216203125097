import React, { useState, useImperativeHandle, forwardRef } from "react";
import colors from "../../constants/colors";
import Button from "react-bootstrap/Button";
import "./style.css";

function Slide({ next }, ref) {
	const [selectedColor, setSelectedColor] = useState(null);

	useImperativeHandle(ref, () => {
		return {
			selectedColor,
		};
	});

	return (
		<div className="container">
			<div className="top">
				<h5 className="test-desc">Clique sobre a cor que você mais gostou no teste</h5>
			</div>
			<div className="middle" id="middle">
				<div className="avaliation-colors">
					{colors.map((color, i) => {
						return (
							<div
								key={`${color}_${i}`}
								className={`colors ${color} ${selectedColor === color ? "selected" : ""}`}
								onClick={() => setSelectedColor(color)}
							></div>
						);
					})}
				</div>
			</div>
			<div className="bottom">
				{selectedColor && (
					<Button
						variant="primary"
						onClick={() => {
							next(1);
						}}
						size="lg"
					>
						Avançar
					</Button>
				)}
			</div>
		</div>
	);
}

const SlideRef = forwardRef(Slide);

export default SlideRef;

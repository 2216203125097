import { openDB } from "idb/with-async-ittr.js";

const STORE_NAME = "pfister";

async function IndexedDB() {
	// window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

	// if (!window.indexedDB) {
	// 	alert("No DB");
	// }

	// if (!('indexedDB' in window)) {
	// 	console.log('This browser doesn\'t support IndexedDB');
	// 	return;
	// }

	const db = await openDB(STORE_NAME + "-db", 1, {
		upgrade(db) {
			db.createObjectStore(STORE_NAME, {
				keyPath: "id",
				autoIncrement: true,
			});
			// store.createIndex("date", "date");
		},
	});

	const add = async (obj) => {
		await db.add(STORE_NAME, obj);
	};

	const getAll = async () => {
		return await db.getAll(STORE_NAME);
	};

	const clear = async () => {
		return await db.clear(STORE_NAME);
	};

	return {
		add,
		getAll,
		clear,
	};
}

export default IndexedDB;
